<template>
  <div class="new-login">
    <div class="new-login-container">
      <div class="__left">
        <template v-if="step === 1">
          <div class="__head">
            <div class="__title">
              <template v-if="isMail">Вход в почту</template>
              <template v-else>
                Для каждой вашей цели<br>
                мы подобрали детальную<br>
                программу питания и тренировок
              </template>
            </div>
          </div>
          <div class="__controls">
            <div class="input-group">
              <input type="tel" placeholder="номер телефона" v-mask="'+7 (###) ### - ## - ##'" v-model="phone"
                key="phone" v-on:keyup.enter="handleSubmit" />
            </div>
            <div class="__btn-container">
              <a href="#" @click.prevent="handleSubmit" class="btn" :class="{ error }">
                {{ error.message || 'получить доступ' }}
              </a>
            </div>
          </div>
        </template>

        <template v-if="step === 2">
          <div class="__head">
            <div class="__title">
              Регистрация
            </div>
            <div class="__description">
              Данный номер телефона еще не зарегистрирован, задайте пароль для входа.<br />
              Пароль должен содержать минимум 6 символов, любые цифры и/или буквы.
            </div>
          </div>
          <div class="__controls">
            <div class="input-group">
              <input type="password" placeholder="пароль" v-model="password" key="password"
                v-on:keyup.enter="handleSubmit" />
            </div>
            <div class="__btn-container">
              <a href="#" @click.prevent="handleSubmit" class="btn" :class="{ error }">
                {{ error.message || 'зарегистрироваться' }}
              </a>
            </div>
          </div>
        </template>

        <template v-if="step === 3">
          <div class="__head">
            <div class="__title">
              Для каждой вашей цели<br>
              мы подобрали детальную<br>
              программу питания и тренировок
            </div>
          </div>
          <div class="__controls">
            <div class="input-group">
              <input type="password" placeholder="пароль" v-model="password" key="password"
                v-on:keyup.enter="handleSubmit" />
            </div>
            <div class="__btn-container">
              <a href="#" @click.prevent="handleSubmit" class="btn" :class="{ error }">
                {{ error.message || 'войти' }}
              </a>
              <a href="#" class="__add" @click.prevent="step = 4">
                забыл пароль
              </a>
            </div>
          </div>
        </template>

        <template v-if="step === 4">
          <div class="__head">
            <div class="__title">
              Восстановление доступа
            </div>
            <div class="__description">
              Чтобы создать новый пароль, введите код из смс
            </div>
          </div>
          <div class="__controls">
            <div class="input-group">
              <input type="number" placeholder="код" v-model="code" v-mask="'####'" key="code"
                v-on:keyup.enter="handleSubmit">
            </div>
            <div class="__btn-container">
              <input type="submit" :value="codeSubmitButton" class="btn" @click.prevent="sendCode"
                :disabled="codeSubmitDisabled" v-show="code.length !== 4" v-on:keyup.enter="handleSubmit">
              <input type="submit" :value="error.message || 'продолжить'" class="btn" :class="{ error: error.message }"
                @click.prevent="handleSubmit" v-show="code.length === 4">
            </div>
          </div>
        </template>

        <template v-if="step === 5">
          <div class="__head">
            <div class="__title">
              Восстановление доступа
            </div>
            <div class="__description">
              Задайте новый пароль для входа.<br>
              Пароль должен содержать минимум 6 символов, любые цифры и/или буквы.
            </div>
          </div>
          <div class="__controls">
            <div class="input-group">
              <input type="password" placeholder="пароль" v-model="password" key="password">
            </div>
            <div class="__btn-container">
              <input type="submit" :value="error.message || 'сменить пароль'" class="btn"
                :class="{ error: error.message }" @click.prevent="handleSubmit">
            </div>
          </div>
        </template>

      </div>
      <div class="__right">
        <img src="/images/login-image.png" alt="Meal Deal">
      </div>
    </div>
    <div class="__copyright">
      Нажимая на кнопку «Получить доступ» и подтверждая номер телефона кодом из SMS, Вы соглашаетесь с подключением
      сервиса «Meal Deal» и его условиями.<br>
      Стоимость услуги 12 рублей в день, оплата со счета телефона. Первые 2 дня бесплатно. Оплата осуществляется
      переводом
      с лицевого счета абонента Tele2 на условиях оферты и условиях оферты ООО РНКО "Единая Касса".
    </div>
  </div>
</template>

<script>
import request from '@/api/axios';
import eventsBus from '@/api/events';
import AppHeader from '@/components/app/Header';

export default {
  components: {
    AppHeader
  },
  data: () => {
    return {
      phone: '',
      password: '',
      passwordConfirm: '',
      code: '',
      step: 1,
      // step: 4,
      error: false,
      time: 30,
      seconds: 30,
      codeSubmitDisabled: true,
      returnUrl: '/',
      referrer: false
    }
  },
  computed: {
    isMail() {
      return this.$route.meta.mail;
    },
    codeSubmitButton: function () {
      if (this.time === 0) return 'отправить еще раз';
      return 'отправить еще раз 0:' + (this.time > 9 ? this.time : '0' + this.time);
    }
  },
  created() {
    if (this.$route.query.ref === 'refreg' && this.$route.query.phone) {
      this.phone = '+' + this.$route.query.phone;
      this.referrer = true;
      this.handleSubmit();
    }
  },
  watch: {
    $route(toPath, fromPath) {
      // this.closeSiteMenu();
      document.body.classList.remove('active-meal');
      if (fromPath.path.indexOf('profile') === -1) this.returnUrl = fromPath.path;
    },
    phone: function () {
      this.error = false;
    },
    agreement: function () {
      this.error = false;
    },
    password: function () {
      this.error = false;
    },
    passwordConfirm: function () {
      this.error = false;
    },
    code: function () {
      this.error = false;
    },
    step: function (newStep, oldStep) {
      if (newStep === 4 && oldStep !== 4) {
        this.sendCode();
      }
    }
  },
  mounted() {
    eventsBus.$emit('page-ready');

    if (this.$route.params) {
      if (this.$route.params.step && this.$route.params.phone) {
        this.step = this.$route.params.step;
        this.phone = this.$route.params.phone;
      }
    }

    let hash = window.location.hash;
    if (hash) {
      if (hash.indexOf('phone')) {
        this.step = 3;
        this.phone = decodeURI(hash.replace('#phone=', ''));
      }
    }
  },
  methods: {
    async handleSubmit() {
      let phone = this.phone.replace(/\s|\(|\)|-/g, '');

      if (this.step === 1) {
        if (phone.length !== 12) {
          if (phone.length === 0) {
            this.error = { message: 'введите номер телефона' };
            return false;
          }
          this.error = { message: 'номер указан неверно' };
          return false;
        }

        const checkUser = (await request({ user: this.$store.state.user, action: 'check_user', options: { phone } })).data;

        if (checkUser.status !== true) {
          this.step = 2;
          // если это реферальная программа и пользователь не найден, то мы его как бы регистрируем с фейковым паролем
          // скрипт идёт дальше с step=2
          if (this.referrer) {
            const password = '123456';
            this.password = password;
            this.passwordConfirm = password;
          } else return false;
        } else {
          this.step = 3;
          return false;
        }
      }

      if (this.step === 2 || this.step === 5) {
        this.passwordConfirm = this.password; // добавил в новой версии
        if (!this.password) {
          this.error = { message: 'введите пароль' };
          return false;
        }

        if (this.password?.length < 6) {
          this.error = { message: 'пароль слишком короткий' };
          return false;
        }

        if (!this.passwordConfirm) {
          this.error = { message: 'подтвердите пароль' };
          return false;
        }

        if (this.password !== this.passwordConfirm) {
          this.error = { message: 'ошибка подтверждения пароля' };
          return false;
        }

        let response = null;
        if (this.step === 2) {
          response = await request({
            user: this.$store.state.user,
            action: 'register',
            options: {
              phone,
              password: this.password,
              password_confirm: this.passwordConfirm
            }
          });
        }
        if (this.step === 5) {
          response = await request({
            user: this.$store.state.user,
            action: 'change_password',
            options: {
              phone,
              password: this.password,
              password_confirm: this.passwordConfirm
            }
          });
        }

        if (response?.data?.status === true) {
          console.log('q2', response.data.user);
          this.$store.commit('login', response.data.user);

          if (!this.isMail) {
            const backUrl = this.$route.params.backUrl || this.$route.query.backUrl || null;
            if (backUrl != null) {
              this.$router.push(backUrl);
            } else {
              this.$router.push('/profile');
            }
          } else {
            const mailLink = (await request({ user: this.$store.state.user, action: 'get_link_to_mail' })).data;
            if (mailLink.link) {
              window.location.href = mailLink.link;
            } else {
              this.$router.push('/profile');
            }
          }
        }
        return false;
      }

      if (this.step === 3) {
        if (this.password.length === 0) {
          this.error = { message: 'введите пароль' };
          return false;
        }

        const login = (await request({ user: this.$store.state.user, action: 'login', options: { phone, password: this.password } })).data;
        if (login.error === true) {
          this.error = { message: 'пароль неверный' };
          return false;
        }

        console.log('q3', login.user);
        this.$store.commit('login', login.user);

        if (!this.isMail) {
          const backUrl = this.$route.params.backUrl || this.$route.query.backUrl || null;
          if (backUrl != null) {
            if (login.user?.payment?.type === 1) {
              this.$router.push(backUrl);
            } else {
              // this.$router.push('/profile/payment');
              this.$router.push('/plan');
            }
          } else {
            this.$router.push('/profile');
          }
        } else {
          const mailLink = (await request({ user: this.$store.state.user, action: 'get_link_to_mail' })).data;
          if (mailLink.link) {
            window.location.href = mailLink.link;
          } else {
            this.$router.push('/profile');
          }
        }
      }

      if (this.step === 4) {
        let isCodeValid = (await request({ user: this.$store.state.user, action: 'check_code', options: { phone, code: this.code } })).data;

        if (isCodeValid.result === false) {
          // if (isCodeValid.status === false) {
          this.error = { message: 'код неверный' };
          return false;
        }
        this.step = 5;
        return false;
      }
    },
    async sendCode() {
      this.startTimer();
      let phone = this.phone.replace(/\s|\(|\)|-/g, '');
      return await request({ user: this.$store.state.user, action: 'forgot_password', options: { phone } });
    },
    startTimer() {
      this.time = this.seconds;
      this.codeSubmitDisabled = true;
      let interval = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(interval);
          this.codeSubmitDisabled = false;
        }
      }, 1000);
    }
  }
}
</script>

<style scoped lang="scss">
.new-login {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.new-login-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
  position: relative;
  top: -100px;

  @media screen and (max-width: 620px) {
    flex-direction: column;
    top: auto;
  }

  .__left {
    flex-basis: 728px;
    margin-right: 54px;

    @media screen and (max-width: 620px) {
      margin-right: 0;
      flex-basis: auto;
    }
  }

  .__right {
    img {
      width: 500px;
    }

    @media screen and (max-width: 620px) {
      order: -1;
      text-align: center;
      margin-bottom: 20px;

      img {
        max-width: 231px;
      }
    }
  }

  .__head {
    margin-bottom: 120px;

    @media screen and (max-width: 620px) {
      margin-bottom: 50px;
    }
  }

  .__description {
    margin-top: 20px;

    @media screen and (max-width: 620px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .__title {
    font-weight: 400;
    font-size: 48px;
    line-height: 65px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.006em;
    color: #333333;
    font-family: 'Optima';

    @media screen and (max-width: 620px) {
      text-align: center;
      font-size: 24px;
      line-height: 25px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .__controls {
    display: flex;
    align-items: baseline;

    @media screen and (max-width: 620px) {
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
    }

    .__btn-container {
      width: calc(100% - 44px);
      position: relative;
      padding-bottom: 50px;

      .__add {
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 400;
        font-size: 14px;
        line-height: 33px;
        letter-spacing: -0.016em;
        text-decoration: underline;
        color: #4D4C4A;
        font-family: 'Optima';

        &:hover {
          text-decoration: none;
        }
      }
    }

    .input-group {
      flex-basis: 48%;
      margin-right: 20px;
      margin-bottom: 0;
      flex-shrink: 0;

      @media screen and (max-width: 620px) {
        width: calc(100% - 44px);
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 35px;
        margin-left: 22px;
      }
    }
  }
}

.__copyright {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.026em;
  color: #727272;
  margin-top: auto;
  margin-bottom: 30px;

  @media screen and (max-width: 620px) {
    padding-top: 30px;
    font-size: 9px;
    margin-bottom: 20px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
</style>
