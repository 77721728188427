<template>
  <div class="intro-section section">
    <div class="section-inside">
      <div class="intro-title section-title">Мы помогаем людям<br class="desktop"> обрести гармонию<br
          class="desktop"><br class="phone"> со своим<br class="phone"> телом
      </div>
      <div class="intro-nav">
        <div class="intro-nav-item">
          <router-link to="/articles">статьи</router-link>
        </div>
        <div class="intro-nav-item">
          <router-link to="/plan">планы питания</router-link>
        </div>
        <div style="flex-basis: 100%; height: 0;"></div>
        <div class="intro-nav-item">
          <router-link to="/trainings">тренировки</router-link>
        </div>
        <div class="intro-nav-item">
          <router-link to="/consultations">консультации</router-link>
        </div>
      </div>
      <div class="btn-container">
        <router-link v-if="!$store.state.user" to="/login" class="btn">начать</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',

}
</script>

<style scoped lang="scss">
.intro-section {
  padding-bottom: 80px;

  @media screen and (min-width: 621px) {
    padding-bottom: 20px;
  }

  .section-title {
    font-weight: 400;
    font-size: 38px;
    line-height: 40px;
    font-style: normal;

    .desktop {
      display: none;
    }

    .phone {
      display: block;
    }

    @media screen and (min-width: 621px) {
      font-weight: 400;
      font-size: 88px;
      line-height: 80px;

      .desktop {
        display: block;
      }

      .phone {
        display: none;
      }
    }
  }

  @media screen and (min-width: 621px) {

    .btn-container {
      padding-bottom: 155px;
    }

    .btn {
      max-width: 340px;
    }
  }

  .section-inside {
    background-image: url('/images/intro-bg-redesign.svg');
    background-size: 290px;
    background-repeat: no-repeat;
    background-position: top 50px right;

    @media screen and (min-width: 621px) {
      background-position: top 100px right;
      background-size: 630px;
    }
  }

  .intro-nav {
    margin-top: 120px;
    padding-bottom: 55px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 621px) {
      margin-top: 25px;
      padding-bottom: 120px;
    }
  }

  .intro-nav-item {
    margin-bottom: 8px;
    margin-right: 10px;

    &:nth-child(2) a {
      padding-left: 37px;
      padding-right: 37px;
    }

    &:nth-child(4) a {
      padding-left: 45px;
      padding-right: 45px;
    }

    &:nth-child(5) a {
      padding-left: 41px;
      padding-right: 41px;
    }

    @media screen and (min-width: 621px) {
      margin-bottom: 10px;
      margin-right: 8px;

      &:nth-child(2) a {
        padding-left: 48px;
        padding-right: 48px;
      }

      &:nth-child(4) a {
        padding-left: 50px;
        padding-right: 50px;
      }

      &:nth-child(5) a {
        padding-left: 45px;
        padding-right: 45px;
      }
    }
  }

  .intro-nav-item a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.016em;
    color: #5F8654;
    border: 1px solid #5F8654;
    border-radius: 17px;
    display: block;
    padding: 5px 26px;

    @media screen and (min-width: 621px) {
      font-size: 14px;
      line-height: 20px;
      padding: 12px 34px;
    }
  }
}
</style>