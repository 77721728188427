<template>
<div class="new-login">
  <app-header/>
  <template v-if="step === 2.8">
    <Success/>
  </template>
  <template v-else>
  <div class="new-login-container" v-if="ready">
    <div class="__left">
      <template v-if="step === 1">
        <div class="__head">
          <div class="__title">
            Для каждой вашей цели<br>
            мы подобрали детальную<br>
            программу питания и тренировок
          </div>
        </div>
        <div class="__controls">
          <div class="input-group">
            <input
                type="tel"
                placeholder="номер телефона"
                v-model="phone"
                id="phone"
                v-mask="'+7 ### ### ## ##'"
            >
          </div>
          <div class="__btn-container">
            <a
                href="#"
                class="btn"
                :class="{preload: preload}"
                @click.prevent="handleSubmit"
            >
              {{ (response.message || 'получить доступ') }}
            </a>
          </div>
        </div>
      </template>

      <template v-if="step === 2 || step === 2.5">
        <div class="__head">
          <div class="__title">
            Для каждой вашей цели<br>
            мы подобрали детальную<br>
            программу питания и тренировок
          </div>
        </div>
        <div class="__controls">
          <div class="input-group">
            <input
                type="number"
                :class="{preload: preload}"
                placeholder="введите код из SMS"
                class="text-input"
                v-mask="'####'"
                v-model="code"
            >
          </div>
          <div class="__btn-container">
            <input
                type="submit"
                :value="codeSubmitButton"
                class="btn gray disabled"
                :class="{preload: preload, error: response.error}"
                @click.prevent="sendCode"
                :disabled="codeSubmitDisabled"
                v-show="code.length !== 4"
            >
            <!--            :value="response.message || (step === 2 ? 'доступ предоставлен' : 'зарегистрироваться')"-->
            <input
                type="submit"
                class="btn"
                :class="{preload: preload, error: response.error}"
                :value="response.message || 'зарегистрироваться'"
                @click.prevent="handleSubmit"
                v-show="code.length === 4"
            >
          </div>
        </div>
      </template>

      <template v-if="step === 2.6">
        <div class="__head" style="margin-bottom: 10px;">
          <div class="__title">
            Программа питания
            для любой цели
          </div>
        </div>
        <div class="__controls">
          <div style="width: 100%;">
            <div id="payment-form"></div>
          </div>
        </div>
      </template>

      <template v-if="step === 2.7">
        <div class="__head">
          <div class="__title">
            Программа питания
            для любой цели
          </div>
        </div>
        <div class="__controls">
          <div class="__btn-container">
            <a
                href="#"
                class="yandex-pay-start-read btn"
                @click.prevent="startRead"
                style="margin-left: auto; margin-right: auto;"
            >
              выбрать план
            </a>
          </div>
        </div>
      </template>
    </div>
    <div class="__right">
      <img src="/images/login-image.png" alt="Meal Deal">
    </div>
  </div>
  <div class="__copyright">
    Нажимая на кнопку «Получить доступ» и подтверждая номер телефона кодом из SMS, Вы соглашаетесь с подключением сервиса «Meal Deal» и его условиями.<br>
    Стоимость услуги 12 рублей в день, оплата со счета телефона. Первые 2 дня бесплатно. Оплата осуществляется переводом с лицевого счета абонента Tele2 на условиях оферты и условиях оферты ООО РНКО "Единая Касса".
  </div>
  </template>
</div>
</template>

<script>
import request from '@/api/axios';
import config from '@/config';
import AppHeader from '@/components/app/Header';
import Success from '@/components/app/Success';

export default {
  components: {
    AppHeader,
    Success
  },
  data: () => ({
    ready: false,
    // tele2phone: '1',
    plans: [],
    step: 1,
    // step: 2.8,
    preload: false,
    response: false,
    phone: '',
    code: '',
    time: 30,
    seconds: 30,
    codeSubmitDisabled: true,
    subscription_id: ''
  }),
  computed: {
    codeSubmitButton: function () {
      if (this.time === 0) return 'отправить еще раз';
      return 'отправить еще раз 0:' + (this.time > 9 ? this.time : '0' + this.time);
    }
  },
  async created() {
    this.ready = false;
    this.plans = await this.getPlans();

    if (this.$route.params.yaPay === true) {
      this.preload = false;
      this.step = 2.5;
    } else if (this.$route.params.yaPayWidget === true) {
      this.preload = false;
      await this.showYandexWidget();
    } else if (this.$route.params.activateTele2 === true || this.$route.query.activateTele2 === '1') {
      const phoneParam = this.$route.params.phone || this.$route.query.phone;;
      if (phoneParam) {
        this.phone = phoneParam;
        let phone = this.phone.replace(/\s|\(|\)|-/g, '');
        console.log('_phone', phone)
        this.preload = false;
        const checkUser = (await request({user: this.$store.state.user, action: 'promo_check_phone', options: {phone, reactivate: true}})).data;
        console.log('created checkUser', checkUser);
        this.subscription_id = checkUser.subscription_id || '';
        this.returnToProfile = true;
        this.step = 2;
      }
    } else {
      try {
        let res = await request({user: this.$store.state.user, action: 'get_tele2_number'});
        // this.tele2phone = JSON.stringify(res);
        if (res && res.data && res.data.phone) {
          this.phone = res.data.phone;
        }
      } catch (e) {
        console.log(e);
        // this.tele2phone = e;
        this.ready = true;
      }
    }
    this.ready = true;
  },
  mounted() {
    this.checkHash();
    window.addEventListener('hashchange', this.checkHash, false);
  },
  watch: {
    phone: function () {
      this.response = false;
    },
    code: function (val) {
      this.response = false;
      if (val.length === 4) this.handleSubmit();
    },
    step: function (newStep, oldStep) {
      this.hideBackground = false; // todo ?
      if (newStep === 2 && oldStep !== 2) {
        this.startTimer();
      }
    }
  },
  methods: {
    async yandexPayCancel() {
      this.$router.push({name: 'payment'});
    },

    startRead() {
      this.$router.push('/plan');
    },

    checkHash() {
      let hash = window.location.hash;
      if (hash && hash === '#success') {
        // this.step = 2.7;
        this.step = 2.8;
      }
    },

    async getPlans() {
      const response = (await request({user: this.$store.state.user, action: 'get_plan_sections'})).data;
      if (response.sections) {
        return response.sections.map(plan => {
          plan.image = config.hostname + plan.image;
          return plan;
        });
      }
      return [];
    },

    async handleSubmit() {
      console.log('this.step', this.step);
      if (this.preload) return false;
      this.preload = true;
      let phone = this.phone.replace(/\s|\(|\)|-/g, '');

      if (this.step === 1) {
        if (phone.length !== 12) {
          if (phone.length === 0) {
            this.response = {message: 'введите номер телефона', type: 'error'};
            this.preload = false;
            return false;
          }
          this.response = {message: 'номер указан неверно', type: 'error'};
          this.preload = false;
          return false;
        }

        const checkUser = (await request({user: this.$store.state.user, action: 'promo_check_phone', options: {phone}})).data;
        console.log('checkUser', checkUser);
        this.subscription_id = checkUser.subscription_id;
        console.log(this.subscription_id);
        /*
        {
        subscription_id,
        status,
        login,
        exist
        }
         */
        // по текущему бэкенду с promo_check_phone вообще не приходит поле status
        // поэтому следующая проверка никогда не проходит и уходит в else
        if (checkUser.status === false) {
          await request({user: this.$store.state.user, action: 'send_code_other', options: {phone}});
          this.step = 2.5;
          this.preload = false;
          return false;
        } else {
          // if (checkUser.login === true) { // user exists
          //   location.href = this.$config.hostname + '/login#phone=' + this.phone;
          //   this.preload = false;
          //   return false;
          // }
          if (checkUser.exists === true) { // ussd account
            location.href = this.$config.hostname + '/login#phone=' + this.phone;
            this.preload = false;
            return false;
          }
          // это добавили последний раз
          if (checkUser.subscription_id && !this.$store.state.user) {
            const backUrl = this.$route.params.backUrl || this.$route.query.backUrl;
            let url = this.$config.hostname + '/login#phone=' + this.phone;
            if (backUrl) url = this.$config.hostname + '/login?backUrl='+ backUrl + '#phone=' + this.phone;
            location.href = url;
            this.preload = false;
            return false;
          }
          this.step = 2;
          this.preload = false;
          return false;
        }
      }

      if (this.step === 2) {
        if (this.response?.type === 'complete') return false;

        // let isCodeValid = (await request({action: 'check_code', options: {phone, code: this.code}})).data;
        let isCodeValid = (await request({user: this.$store.state.user, action: 'promo_check_code', options: {phone, code: this.code, subscription_id: this.subscription_id}})).data;
        if (isCodeValid.result === false) {
          this.response = {message: 'код неверный', type: 'error', error: true};
          this.preload = false;
          return false;
        }

        this.response = {
          message: 'услуга подключена',
          type: 'complete',
          error: false
        };
        this.preload = false;
        setTimeout(async () => {
          if (this.returnToProfile) {
            await request({user: this.$store.state.user, action: 'cancel_other'});
            this.step = 2.8;
            // this.$router.push({
            //   name: 'profile', params: {
            //     changePaymentMode: true
            //   }
            // });
          } else {
            this.step = 2.8;
            // this.$router.push('/');
          }
        }, 1000);
      }

      if (this.step === 2.5) {
        const isCodeValid = (await request({user: this.$store.state.user, action: 'check_code_other', options: {code: this.code}})).data;
        if (isCodeValid.status === false) {
          this.response = {message: 'код неверный', type: 'error'};
          this.preload = false;
          return false;
        }

        await this.showYandexWidget();
      }
    },

    async sendCode() {
      this.startTimer();
      let phone = this.phone.replace(/\s|\(|\)|-/g, '');
      return await request({user: this.$store.state.user, action: 'promo_check_phone', options: {phone}});
    },

    startTimer() {
      this.time = this.seconds;
      this.codeSubmitDisabled = true;
      let interval = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(interval);
          this.codeSubmitDisabled = false;
        }
      }, 1000);
    },

    async showYandexWidget() {
      const yandexToken = (await request({
        user: this.$store.state.user,
        action: 'yandex_confirmation_token',
      })).data;
      this.hideBackground = true; // todo ?
      this.step = 2.6;
      console.log(yandexToken, 'w2', yandexToken.token);

      this.$nextTick(() => {
        setTimeout(() => {
          if (yandexToken.token) {
            const checkout = new window.YooMoneyCheckoutWidget({
              confirmation_token: yandexToken.token,
              return_url: 'http://meal-deal.ru/promo#success',
              embedded_3ds: false,
              error_callback(error) {
                console.log(error);
              }
            });
            checkout.render('payment-form');
          }
        }, 500);
      });
    },
  }
}
</script>

<style scoped lang="scss">

.new-login {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.new-login-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
  position: relative;
  top: -100px;

  @media screen and (max-width: 620px) {
    flex-direction: column;
    top: auto;
  }

  .__left {
    flex-basis: 728px;
    margin-right: 54px;

    @media screen and (max-width: 620px) {
      margin-right: 0;
      flex-basis: auto;
    }
  }

  .__right {
    img {
      width: 500px;
    }

    @media screen and (max-width: 620px) {
      order: -1;
      text-align: center;
      margin-bottom: 20px;

      img {
        max-width: 231px;
      }
    }
  }

  .__head {
    margin-bottom: 120px;

    @media screen and (max-width: 620px) {
      margin-bottom: 50px;
    }
  }

  .__description {
    margin-top: 20px;

    @media screen and (max-width: 620px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .__title {
    font-weight: 400;
    font-size: 48px;
    line-height: 65px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.006em;
    color: #333333;
    font-family: 'Optima';

    @media screen and (max-width: 620px) {
      text-align: center;
      font-size: 24px;
      line-height: 25px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .__controls {
    display: flex;
    align-items: baseline;

    @media screen and (max-width: 620px) {
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
    }

    .__btn-container {
      width: 100%;
      position: relative;
      padding-bottom: 50px;

      .__add {
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 400;
        font-size: 14px;
        line-height: 33px;
        letter-spacing: -0.016em;
        text-decoration: underline;
        color: #4D4C4A;
        font-family: 'Optima';

        &:hover {
          text-decoration: none;
        }
      }
    }

    .input-group {
      flex-basis: 48%;
      margin-right: 20px;
      margin-bottom: 0;
      flex-shrink: 0;

      @media screen and (max-width: 620px) {
        width: calc(100% - 44px);
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 35px;
        margin-left: 22px;
      }
    }
  }
}

.__copyright {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.026em;
  color: #727272;
  margin-top: auto;
  margin-bottom: 30px;

  @media screen and (max-width: 620px) {
    padding-top: 30px;
    font-size: 9px;
    margin-bottom: 20px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
</style>
