<template>
  <div class="section policy-page">
    <div class="section-inside">
      <div class="policy-text">
        <h3>Политика обработки персональных данных</h3>
        <div class="policy-text-container">
          <h2>1. Общие положения</h2>

          <p>Политика обработки персональных данных (далее — Политика) разработана в соответствии
            с Федеральным законом от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — ФЗ-152).
            Настоящая Политика определяет порядок обработки персональных данных и меры по обеспечению
            безопасности
            персональных данных <b>ИП Куликова С.Д.</b> (далее — Оператор) с целью защиты прав и свобод человека
            и
            гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность
            частной
            жизни, личную и семейную тайну.</p>

          <p>В Политике используются следующие основные понятия:<br>
            <b>автоматизированная обработка персональных данных</b> — обработка персональных данных
            с помощью средств вычислительной техники;<br>
            <b>блокирование персональных данных</b> — временное прекращение обработки персональных данных (за
            исключением
            случаев, если обработка необходима для уточнения персональных данных);<br>
            <b>обезличивание персональных данных</b> — действия, в результате которых невозможно определить без
            использования дополнительной информации принадлежность персональных данных конкретному
            субъекту персональных данных;<br>
            <b>обработка персональных данных</b> — любое действие (операция) или совокупность действий
            (операций),
            совершаемых с использованием средств автоматизации или без использования таких средств с
            персональными
            данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
            изменение),
            извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
            блокирование, удаление, уничтожение персональных
            данных;<br>
            <b>пользователь</b> – любой посетитель веб-сайта https://meal-deal.ru/.<br>
            <b>оператор</b> — государственный орган, муниципальный орган, юридическое или физическое лицо,
            самостоятельно
            или совместно с другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки персональных данных, состав
            персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными
            данными;<br>
            <b>персональные данные</b> — любая информация, относящаяся к прямо или косвенно определенному или
            определяемому
            Пользователю веб-сайта https://meal-deal.ru/ (субъекту персональных данных);<br>
            <b>предоставление персональных данных</b> — действия, направленные на раскрытие персональных данных
            определенному лицу или определенному кругу лиц;<br>
            <b>распространение персональных данных</b> — действия, направленные на раскрытие персональных данных
            неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными
            неограниченного круга лиц, в том числе обнародование персональных
            данных в средствах массовой информации, размещение в информационно - телекоммуникационных сетях или
            предоставление доступа к персональным данным каким-либо иным способом;<br>
            <b>трансграничная передача персональных данных</b> — передача персональных данных на территорию
            иностранного
            государства органу власти иностранного государства, иностранному физическому или
            иностранному юридическому лицу;<br>
            <b>уничтожение персональных данных</b> — действия, в результате которых невозможно восстановить
            содержание
            персональных данных в информационной системе персональных данных и (или)
            результате которых уничтожаются материальные носители персональных данных.</p>

          <h2>2. Принципы и условия обработки персональных данных</h2>
          <p><u>2.1. Принципы обработки персональных данных</u></p>
          <p>Обработка персональных данных у Оператора осуществляется на основе следующих принципов:<br>
            — законности и справедливой основы;<br>
            — ограничения обработки персональных данных достижением конкретных, заранее определенных и законных
            целей;<br>
            — недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;<br>
            — недопущения объединения баз данных, содержащих персональные данные, обработка которых
            осуществляется в целях, несовместимых между собой;<br>
            — обработки только тех персональных данных, которые отвечают целям их обработки;<br>
            — соответствия содержания и объема обрабатываемых персональных данных заявленным целям
            обработки;<br>
            — недопущения обработки персональных данных, избыточных по отношению к заявленным целям их
            обработки;<br>
            — обеспечения точности, достаточности и актуальности персональных данных по отношению к целям
            обработки персональных данных;<br>
            — уничтожения либо обезличивания персональных данных по достижении целей их обработки или в случае
            утраты необходимости в достижении этих целей, при невозможности устранения Оператором допущенных
            нарушений персональных данных, если иное не предусмотрено федеральным законом.</p>

          <p><u>2.2. Условия обработки персональных данных</u></p>
          <p>Оператор производит обработку персональных данных при наличии хотя бы одного из следующих условий:
            — обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку
            его персональных данных;<br>
            — обработка персональных данных необходима для достижения целей, предусмотренных
            международным договором Российской Федерации или законом, для осуществления и выполнения возложенных
            законодательством Российской Федерации на оператора функций, полномочий и обязанностей;<br>
            — обработка персональных данных необходима для осуществления правосудия, исполнения
            судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с
            законодательством Российской Федерации об исполнительном производстве;<br>
            — обработка персональных данных необходима для исполнения договора, стороной которого либо
            выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для
            заключения договора по инициативе субъекта персональных данных или договора, по которому субъект
            персональных данных будет являться выгодоприобретателем или поручителем;<br>
            — обработка персональных данных необходима для осуществления прав и законных интересов
            оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом
            не нарушаются права и свободы субъекта персональных данных;<br>
            — осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым
            предоставлен субъектом персональных данных либо по его просьбе (далее — общедоступные
            персональные данные);<br>
            — осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию
            в соответствии с федеральным законом.</p>

          <p><u>2.3. Конфиденциальность персональных данных</u></p>
          <p>Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и
            не распространять персональные данные без согласия субъекта персональных данных, если иное не
            предусмотрено федеральным законом.</p>

          <p><u>2.4. Общедоступные источники персональных данных</u></p>
          <p>В целях информационного обеспечения у Оператора могут создаваться общедоступные источники
            персональных данных субъектов, в том числе справочники и адресные книги. В общедоступные источники
            персональных данных с письменного согласия субъекта могут включаться его фамилия, имя, отчество,
            дата и место рождения, номера контактных телефонов, адрес электронной почты и иные персональные
            данные, сообщаемые субъектом персональных данных. Сведения о субъекте должны быть в любое время
            исключены из общедоступных источников персональных данных по требованию субъекта либо по решению
            суда или иных уполномоченных государственных органов.</p>

          <p><u>2.5. Специальные категории персональных данных</u></p>
          <p>Обработка Оператором специальных категорий персональных данных, касающихся расовой,
            национальной принадлежности, политических взглядов, религиозных или философских убеждений,
            состояния здоровья, интимной жизни, допускается в случаях, если:<br>
            — субъект персональных данных дал согласие в письменной форме на обработку своих персональных
            данных;<br>
            — персональные данные сделаны общедоступными субъектом персональных данных;<br>
            — обработка персональных данных осуществляется в соответствии с законодательством о государственной
            социальной помощи, трудовым законодательством, законодательством Российской Федерации о пенсиях по
            государственному пенсионному обеспечению, о трудовых пенсиях;<br>
            — обработка персональных данных необходима для защиты жизни, здоровья или иных жизненноважных
            интересов субъекта персональных данных либо жизни, здоровья или иных жизненно важных интересов
            других лиц и получение согласия субъекта персональных данных невозможно;<br>
            — обработка персональных данных осуществляется в медико-профилактических целях, в целях установления
            медицинского диагноза, оказания медицинских и медико-социальных услуг при условии, что обработка
            персональных данных осуществляется лицом, профессионально
            занимающимся медицинской деятельностью и обязанным в соответствии с законодательством Российской
            Федерации сохранять врачебную тайну;<br>
            — обработка персональных данных необходима для установления или осуществления прав субъекта
            персональных данных или третьих лиц, а равно и в связи с осуществлением правосудия;<br>
            — обработка персональных данных осуществляется в соответствии с законодательством об обязательных
            видах страхования, со страховым законодательством.</p>

          <p>Обработка специальных категорий персональных данных должна быть незамедлительно прекращена,
            если устранены причины, вследствие которых осуществлялась их обработка, если иное не установлено
            федеральным законом.</p>

          <p><u>2.6. Поручение обработки персональных данных другому лицу</u></p>
          <p>Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных
            данных, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом
            договора. Лицо, осуществляющее обработку персональных данных по поручению Оператора, обязано
            соблюдать принципы и правила обработки персональных данных,
            предусмотренные ФЗ-152.</p>

          <p><u>2.7. Трансграничная передача персональных данных</u></p>
          <p>Оператор обязан убедиться в том, что иностранным государством, на территорию которого предполагается
            осуществлять передачу персональных данных, обеспечивается адекватная защита прав субъектов
            персональных данных, до начала осуществления такой передачи.</p>

          <p>Трансграничная передача персональных данных на территории иностранных государств, не обеспечивающих
            адекватной защиты прав субъектов персональных данных, может осуществляться в случаях:<br>
            — наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его
            персональных данных;<br>
            — исполнения договора, стороной которого является субъект персональных данных.</p>

          <h2>2. Принципы и условия обработки персональных данных</h2>

          <p><u>3.1. Согласие субъекта персональных данных на обработку его персональных данных</u></p>
          <p>Субъект персональных данных принимает решение о предоставлении его персональных данных и дает
            согласие на их обработку свободно, своей волей и в своем интересе. Согласие на обработку
            персональных данных может быть дано субъектом персональных данных или его представителем в любой
            позволяющей подтвердить факт его получения форме, если иное не установлено федеральным законом.
            Обязанность предоставить доказательство получения согласия субъекта персональных данных на обработку
            его персональных данных или доказательство наличия оснований, указанных в ФЗ-152, возлагается на
            Оператора.</p>

          <p><u>3.2. Права субъекта персональных данных</u></p>
          <p>Субъект персональных данных имеет право на получение у Оператора информации, касающейся обработки его
            персональных данных, если такое право не ограничено в соответствии с федеральными законами. Субъект
            персональных данных вправе требовать от Оператора уточнения его персональных данных, их блокирования
            или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными,
            незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать
            предусмотренные законом меры по защите своих прав.</p>

          <p>Запрещается принятие на основании исключительно автоматизированной обработки персональных данных
            решений, порождающих юридические последствия в отношении субъекта персональных данных или иным
            образом затрагивающих его права и законные интересы, за исключением случаев, предусмотренных
            федеральными законами, или при наличии согласия в письменной форме субъекта персональных данных.</p>

          <p>Если субъект персональных данных считает, что Оператор осуществляет обработку его персональных
            данных с нарушением требований ФЗ-152 или иным образом нарушает его права и свободы, субъект
            персональных данных вправе обжаловать действия или бездействие Оператора в Уполномоченный орган по
            защите прав субъектов персональных данных или в судебном порядке.</p>

          <p>Субъект персональных данных имеет право на защиту своих прав и законных интер</p>

          <h2>4. Обеспечение безопасности персональных данных</h2>

          <p>Безопасность персональных данных, обрабатываемых Оператора, обеспечивается реализацией правовых,
            организационных и технических мер, необходимых для обеспечения требований федерального
            законодательства в области защиты персональных данных</p>

          <p>Для предотвращения несанкционированного доступа к персональным данным Оператором применяются
            следующие организационно-технические меры:<br>
            —ограничение состава лиц, имеющих доступ к персональным данным;<br>
            —ознакомление субъектов с требованиями федерального законодательства и нормативных документов
            Оператора по обработке и защите персональных данных;<br>
            —организация учета, хранения и обращения носителей информации;<br>
            —проверка готовности и эффективности использования средств защиты информации;<br>
            —разграничение доступа пользователей к информационным ресурсам и программно-аппаратным средствам
            обработки информации;<br>
            —использование антивирусных средств и средств восстановления системы защиты персональных данных;</p>

          <h2>5. Заключительные положения</h2>
          <p>Иные права и обязанности Оператора, как оператора персональных данных определяются законодательством
            Российской Федерации в области персональных данных.</p>

        </div>
      </div>
    </div>
    <app-footer/>
  </div>
</template>

<script>
import AppFooter from '@/components/app/Footer';

export default {
  components: {
    AppFooter
  }
}
</script>