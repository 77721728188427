<template>
  <div class="section-inside">
    <h1>
      <span class="_a-title-big">Meal Deal</span>
      <span class="_a-title-small">сервис, посвященный здоровому образу жизни</span>
    </h1>
    <p class="_a-p1">В современном мире мы все больше уделяем внимания своему здоровью: оно влияет на продолжительность
      нашей
      жизни, внешний вид и внутреннее состояние. Всем хочется чувствовать себя легко и непринужденно.</p>
    <p class="_a-p2">Понимая потребности современного общества, мы создали сервис Meal Deal – сервис, посвященный
      здоровому
      образу жизни.<br class="desktop"> Мы хотим помочь людям обрести гармонию со своим телом путем познания принципов
      правильного питания,
      спортивного заряда<br class="desktop"> и комфортного состояния всего организма.</p>
    <h2>Meal Deal разделен на три части,<br class="desktop">
      и каждый сможет найти то, что ему по душе:</h2>
    <ul class="_p-list">
      <li>
        <img src="/images/about-1-redesign.png" alt="1">
        <div class="_p-item-text">
          <p>Статьи о здоровом образе жизни, нашем организме и теле. Здесь найдут для себя полезные знания как новички,
            так и
            опытные пропагандисты принципов ЗОЖ</p>
          <router-link to="/articles" class="btn-outlined btn-gray btn-full-width">статьи</router-link>
        </div>
      </li>
      <li>
        <div class="_p-item-text">
          <p>План питания, составленный специально под ваши цели. Опытные и сертифицированные диетологи составили для
            наших
            пользователей меню на каждый день, сбалансированное и одновременно с тем разнообразное. А чтобы вам было
            удобнее, мы
            расписали рецепты завтраков, обедов, ужинов и даже перекусов, тщательно отбирая для вас самое лучшее.</p>
          <router-link to="/plan" class="btn-outlined btn-gray btn-full-width">планы</router-link>
        </div>
        <img src="/images/about-2-redesign.png" alt="2">
      </li>
      <li>
        <img src="/images/about-3-redesign.png" alt="3">
        <div class="_p-item-text">
          <p>Консультации с диетологами, позволяющие вам получить абсолютно индивидуальный подход. Мы поможем вам
            получить ответ на
            любой вопрос: начиная от самого простого вроде «с чем лучше сочетать картофельное пюре» и заканчивая
            составлением
            индивидуального плана питания и тренировок, подобранного согласно вашим параметрам: росту и весу, возрасту,
            состоянию
            вашего здоровья, вашего распорядка дня и <br>ваших вкусовых предпочтений.</p>
          <router-link to="/consultations" class="btn-outlined btn-gray btn-full-width">консультации</router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped lang="scss">
.desktop {
  display: block;

  @media screen and (max-width: 620px) {
    display: none;
  }
}

h1 {
  font-family: 'Optima';
  margin-bottom: 60px;

  @media screen and (max-width: 620px) {
    margin-bottom: 40px;
  }

  ._a-title-big {
    display: block;
    font-weight: 400;
    font-size: 88px;
    line-height: 77px;
    color: #333;
    letter-spacing: -0.016em;

    @media screen and (max-width: 620px) {
      font-size: 48px;
      line-height: 77px;
    }
  }

  ._a-title-small {
    display: block;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.016em;
    color: #000;

    @media screen and (max-width: 620px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
}

._a-p1 {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.016em;
  color: #333;
  padding-bottom: 20px;
  border-bottom: 1px solid #727272;
  margin-bottom: 10px;

  @media screen and (max-width: 620px) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 19px;
  }
}

._a-p2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.016em;
  color: #000;
  font-family: 'Optima';
  margin-bottom: 60px;

  @media screen and (max-width: 620px) {
    margin-bottom: 50px;
    margin-top: 15px;
    font-size: 16px;
    line-height: 20px;
  }
}

h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 35px;
  letter-spacing: -0.016em;
  color: #000;
  font-family: 'Optima';
  margin-bottom: 90px;
  text-align: left;
  border: none;
  margin-top: 0;

  @media screen and (max-width: 620px) {
    margin-bottom: 36px;
    font-size: 34px;
    line-height: 33px;
  }
}

ul._p-list {
  counter-reset: section;

  li {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    padding: 0;
    counter-increment: section;

    @media screen and (max-width: 620px) {
      flex-direction: column;
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:before {
      display: none;
    }

    img {
      @media screen and (max-width: 620px) {
        display: none;
        //order: -1;
        //max-width: 100% !important;
        //margin-right: 0 !important;
        //margin-left: 0 !important;
      }
    }

    &:nth-child(1) {
      img {
        max-width: 376px;
        margin-right: 30px;
      }
    }

    &:nth-child(2) {
      img {
        max-width: 391px;
        margin-left: 30px;
      }
    }

    &:nth-child(3) {
      img {
        max-width: 362px;
        margin-right: 30px;
      }
    }

    ._p-item-text {
      position: relative;

      @media screen and (max-width: 620px) {
        padding-left: 83px;

        &>p {
          margin: 0;
          font-size: 15px !important;
          line-height: 20px !important;
          letter-spacing: -0.016em !important;
        }
      }

      &:before {
        display: none;

        @media screen and (max-width: 620px) {
          content: counter(section);
          display: block;
          font-weight: 400;
          font-size: 108px;
          line-height: 46px;
          letter-spacing: -0.016em;
          color: #333;
          font-family: 'Optima';
          position: absolute;
          left: 0;
          top: 20px;
        }
      }

      p {
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.016em;
        color: #333;
        margin-bottom: 25px;
      }

      .btn-outlined {
        @media screen and (max-width: 620px) {
          margin-left: -83px;
          width: calc(100% + 83px);
          max-width: calc(100% + 83px);
        }
      }
    }
  }
}
</style>