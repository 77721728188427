<template>
    <div class="feedback-page">
        <div class="feedback-form-container">
            <div>
                <div class="section-title">Контакты</div>
                <br><br>
                <p>
                    email:
                    <a style="color: #000; text-decoration: underline;" href="mailto:service@meal-deal.ru">
                        service@meal-deal.ru
                    </a>
                </p>
                <p>
                    ИП Куликова С. Д.<br>
                    ОГРНИП 320774600015546
                </p>
            </div>

            <div class="feedback-page-footer">
                <app-footer/>
            </div>
        </div>
    </div>
</template>

<script>
  import AppFooter from '@/components/app/Footer';

  export default {
    components: {
      AppFooter
    }
  }
</script>